import * as num from "fp-ts/lib/number";
import * as Ord_ from "fp-ts/lib/Ord";

import { Branded, Int } from "../../../../utils/Brand";

export type FuelTypeIdBrand = { readonly FuelTypeId: unique symbol };
export type FuelTypeId = Branded<Int, FuelTypeIdBrand>;

export type FuelType = {
    id: FuelTypeId;
    name: string;
    order?: number;
};

export const IdOrd = num.Ord as Ord_.Ord<FuelTypeId>;
export const Ord = Ord_.fromCompare<FuelType>((a, b) =>
    a.order != null
        ? b.order != null
            ? num.Ord.compare(a.order, b.order)
            : -1
        : b.order != null
        ? 1
        : IdOrd.compare(a.id, b.id)
);
