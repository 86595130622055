import { css } from "@emotion/react";

import colors from "../../../utils/colors";

export const activeBorderBottom =
    ({ size, target = "&", side = "bottom", color = "currentColor" } = {}) =>
    ({ theme }) => {
        const axis = ["top", "bottom"].includes(side)
            ? "horizontal"
            : "vertical";
        const transform = (v) =>
            `scale${{ horizontal: "X", vertical: "Y" }[axis]}(${v})`;

        return css`
            ${target} {
                position: relative;
                border-${side}: ${size} solid transparent;

                &::after {
                    position: absolute;
                    content: "";
                    background: ${color};
                    ${{ horizontal: "left", vertical: "top" }[axis]}: 0;
                    ${side}: -${size};

                    transition: transform 200ms;
                    transform: ${transform(0)};
                    ${
                        { horizontal: "height", vertical: "width" }[axis]
                    }: ${size};
                    ${{ horizontal: "width", vertical: "height" }[axis]}: 100%;
                }
            }

            /* 1ms delay as it otherwise causes a display bug in Safari */
            ${theme.selectors.hoverOrActive.css`
                /* stylelint-disable-next-line no-duplicate-selectors */
                ${target}::after {
                    transition-delay: 1ms;
                    transform: ${transform(1)};
                }
            `}
        `;
    };

export const activeLink = ({ theme, target = "&" }) => css`
    ${target} {
        color: ${colors.blue};
        cursor: pointer;
        opacity: 1;
        transition: color 200ms, opacity 200ms;
    }

    ${activeBorderBottom({ size: "4px", target })({ theme })}

    ${theme.selectors.hoverOrActive.css`
/* stylelint-disable-next-line no-duplicate-selectors */
        ${target} {
            color: ${colors.red};
        }
    `}

    ${theme.selectors.hover.css`
/* stylelint-disable-next-line no-duplicate-selectors */
        ${target} {
            opacity: 0.7;
        }
    `}
`;
