import * as Ord_ from "fp-ts/lib/Ord";
import * as str from "fp-ts/lib/string";

import { Travis } from "./NormalOrTravis";

export enum TravisStationTypeId {
    travis = "travis",
}

export type TravisStationType = {
    id: Travis<TravisStationTypeId>;
    name: string;
};

export const IdOrd = str.Ord as Ord_.Ord<TravisStationTypeId>;
export const Ord = Ord_.fromCompare<TravisStationType>((a, b) =>
    IdOrd.compare(a.id.value, b.id.value)
);
