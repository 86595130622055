import * as Apply from "fp-ts/lib/Apply";
import * as Eq from "fp-ts/lib/Eq";
import { Monad2 } from "fp-ts/lib/Monad";

import { ApiResponse } from "../apiBindings";
import { DataResponse } from "../apiBindings/fetcher";

export type AsyncDataNotLoaded = {
    state: "not-loaded";
    data: undefined;
    error: undefined;
};
export type AsyncDataLoading = {
    state: "loading";
    data: undefined;
    error: undefined;
};
export type AsyncDataLoaded<a> = {
    state: "loaded";
    data: a;
    error: undefined;
};
export type AsyncDataError<e> = {
    state: "error";
    data: undefined;
    error: e;
};
export type AsyncDataDone<e, a> = AsyncDataLoaded<a> | AsyncDataError<e>;
export type AsyncData<e, a> =
    | AsyncDataNotLoaded
    | AsyncDataLoading
    | AsyncDataLoaded<a>
    | AsyncDataError<e>;

export const notLoaded = <e, a>(): AsyncData<e, a> => ({
    state: "not-loaded",
    data: undefined,
    error: undefined,
});
export const isNotLoaded = (
    ad: AsyncData<unknown, unknown>
): ad is AsyncDataNotLoaded => ad.state === "not-loaded";
export const loading = <e, a>(): AsyncData<e, a> => ({
    state: "loading",
    data: undefined,
    error: undefined,
});
export const isLoading = (
    ad: AsyncData<unknown, unknown>
): ad is AsyncDataLoading => ad.state === "loading";
export const loaded = <e, a>(data: a): AsyncData<e, a> => ({
    state: "loaded",
    data,
    error: undefined,
});
export const isLoaded = <a>(
    ad: AsyncData<unknown, a>
): ad is AsyncDataLoaded<a> => ad.state === "loaded";
export const error = <e, a>(error: e): AsyncData<e, a> => ({
    state: "error",
    data: undefined,
    error,
});
export const isError = <e>(
    ad: AsyncData<e, unknown>
): ad is AsyncDataError<e> => ad.state === "error";
export const isDone = <e, a>(ad: AsyncData<e, a>): ad is AsyncDataDone<e, a> =>
    isLoaded(ad) || isError(ad);

export const URI = "AsyncData";
export type URI = typeof URI;
declare module "fp-ts/lib/HKT" {
    interface URItoKind2<E, A> {
        readonly [URI]: AsyncData<E, A>;
    }
}

export const Monad: Monad2<URI> = {
    URI,
    of: loaded,
    ap: (fab, fa) =>
        isLoaded(fa) ? (isLoaded(fab) ? loaded(fab.data(fa.data)) : fab) : fa,
    map: (fa, f) => (isLoaded(fa) ? loaded(f(fa.data)) : fa),
    chain: (fa, f) => (isLoaded(fa) ? f(fa.data) : fa),
};

/**
 * Fold an asyncData into a different type by matching every possible state;
 * this specific variant treats not loaded and loading the same.
 * @example asyncData.foldLoading(onLoading, onLoaded, onError)(asyncData.notLoaded()) == onLoading()
 * @example asyncData.foldLoading(onLoading, onLoaded, onError)(asyncData.loading()) == onLoading()
 * @example asyncData.foldLoading(onLoading, onLoaded, onError)(asyncData.loaded(val)) == onLoaded(val)
 * @example asyncData.foldLoading(onLoading, onLoaded, onError)(asyncData.error(err)) == onError(err)
 */
export const foldLoading = <e, a, b>(
    onLoading: () => b,
    onLoaded: (a: a) => b,
    onError: (e: e) => b,
    val: AsyncData<e, a>
) =>
    isLoaded(val)
        ? onLoaded(val.data)
        : isError(val)
        ? onError(val.error)
        : onLoading();

/**
 * The value if loaded, otherwise undefined.
 * @example asyncData.toUndefined(asyncData.notLoaded()) == undefined
 * @example asyncData.toUndefined(asyncData.loading()) == undefined
 * @example asyncData.toUndefined(asyncData.loaded(val)) == val
 * @example asyncData.toUndefined(asyncData.error(err)) == undefined
 */
export const toUndefined = <a>(val: AsyncData<unknown, a>): a | undefined =>
    foldLoading(
        () => undefined,
        (innerVal: a) => innerVal,
        () => undefined,
        val
    );

export const fromApiResponse = <a>(
    val: Promise<ApiResponse<DataResponse<a>>>
): Promise<AsyncData<any, a>> =>
    val.then(
        ({ data }) => loaded(data),
        (e) => error(e)
    );

export const map = <e, a, b>(
    f: (a: a) => b,
    fa: AsyncData<e, a>
): AsyncData<e, b> => Monad.map(fa, f);
export const sequenceS = Apply.sequenceS(Monad);
export const sequenceT = Apply.sequenceT(Monad);

export const getEq = <e, a>(
    eqE: Eq.Eq<e>,
    eqA: Eq.Eq<a>
): Eq.Eq<AsyncData<e, a>> =>
    Eq.fromEquals<AsyncData<e, a>>((a, b) =>
        a.state === b.state
            ? isNotLoaded(a)
                ? isNotLoaded(b)
                : isLoading(a)
                ? isLoading(b)
                : isLoaded(a)
                ? isLoaded(b) && eqA.equals(a.data, b.data)
                : isError(a)
                ? isError(b) && eqE.equals(a.error, b.error)
                : (false as never)
            : false
    );
export const getEq_ = <a>(eqA: Eq.Eq<a>) =>
    getEq<unknown, a>(
        Eq.fromEquals((a, b) => a === b),
        eqA
    );
