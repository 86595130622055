import React, { ComponentProps, FC } from "react";

import ArrowLeftIcon from "../ArrowLeftIcon";
import ArrowRightIcon from "../ArrowRightIcon";
import BistroIcon from "../BistroIcon";
import BookmarkIcon from "../Bookmark";
import BurgermenueIcon from "../BurgermenueIcon";
import CafeIcon from "../CafeIcon";
import CalculatorIcon from "../CalculatorIcon";
import CarCareIcon1 from "../CarCareIcon1";
import CarCareIcon2 from "../CarCareIcon2";
import CarCareIcon3 from "../CarCareIcon3";
import CarCareIcon4 from "../CarCareIcon4";
import CheckIcon from "../CheckIcon";
import ChevronIcon from "../ChevronIcon";
import ClockIcon from "../ClockIcon";
import CloseIcon from "../CloseIcon";
import ConstructionIcon from "../ConstructionIcon";
import CopyIcon from "../CopyIcon";
import DeleteIcon from "../DeleteIcon";
import DirectionsIcon from "../DirectionsIcon";
import DownloadIcon from "../DownloadIcon";
import DustBin from "../DustbinIcon";
import EditIcon from "../EditIcon";
import FilterIcon from "../FilterIcon";
import HeadroomIcon from "../HeadroomIcon";
import InfoIcon from "../InfoIcon";
import InfoSmallIcon from "../InfoSmallIcon";
import LKWParkingIcon from "../LKWParkingIcon";
import LocationIcon from "../LocationIcon";
import MailIcon from "../MailIcon";
import PhoneIcon from "../PhoneIcon";
import PlusIcon from "../PlusIcon";
import RestaurantIcon from "../RestaurantIcon";
import RouteIcon from "../RouteIcon";
import SearchIcon from "../SearchIcon";
import ShopIcon from "../ShopIcon";
import SortIcon from "../SortIcon";
import StarIcon from "../StarIcon";
import TruckIcon from "../TruckIcon";
import UserIcon from "../UserIcon";
import WarningIcon from "../WarningIcon";
import WorldIcon from "../WorldIcon";
import ZielIcon from "../ZielIcon";

export enum PredefinedIconType {
    arrowLeft = "arrow-left",
    arrowRight = "arrow-right",
    bistro = "bistro",
    burgerMenu = "burger-menue",
    cafe = "cafe",
    calculator = "calculator",
    chevron = "chevron",
    clock = "clock",
    close = "close",
    contruction = "contruction",
    delete = "delete",
    directions = "directions",
    download = "download",
    filter = "filter",
    headroom = "headroom",
    info = "info",
    lkwParking = "lkw-parking",
    location = "location",
    mail = "mail",
    phone = "phone",
    restaurant = "restaurant",
    route = "route",
    search = "search",
    shop = "shop",
    sort = "sort",
    user = "user",
    warning = "warning",
    ziel = "ziel",
    edit = "edit",
    dustbin = "dustbin",
    world = "world",
    check = "check",
    star = "star",
    truck = "truck",
    copy = "copy",
    plus = "plus",
    infoSmall = "infoSmall",
    bookmark = "bookmark",
    carCare1 = "carCareIcon1",
    carCare2 = "carCareIcon2",
    carCare3 = "carCareIcon3",
    carCare4 = "carCareIcon4",
}

const icons: {
    [k in PredefinedIconType]: FC<
        k extends PredefinedIconType.ziel
            ? { color?: string; hoverColor?: any; innerColor?: string }
            : { color?: string; hoverColor?: any }
    >;
} = {
    [PredefinedIconType.arrowLeft]: ArrowLeftIcon,
    [PredefinedIconType.arrowRight]: ArrowRightIcon,
    [PredefinedIconType.bistro]: BistroIcon,
    [PredefinedIconType.burgerMenu]: BurgermenueIcon,
    [PredefinedIconType.cafe]: CafeIcon,
    [PredefinedIconType.calculator]: CalculatorIcon,
    [PredefinedIconType.chevron]: ChevronIcon,
    [PredefinedIconType.clock]: ClockIcon,
    [PredefinedIconType.close]: CloseIcon,
    [PredefinedIconType.contruction]: ConstructionIcon,
    [PredefinedIconType.delete]: DeleteIcon,
    [PredefinedIconType.directions]: DirectionsIcon,
    [PredefinedIconType.download]: DownloadIcon,
    [PredefinedIconType.filter]: FilterIcon,
    [PredefinedIconType.headroom]: HeadroomIcon,
    [PredefinedIconType.info]: InfoIcon,
    [PredefinedIconType.lkwParking]: LKWParkingIcon,
    [PredefinedIconType.location]: LocationIcon,
    [PredefinedIconType.mail]: MailIcon,
    [PredefinedIconType.phone]: PhoneIcon,
    [PredefinedIconType.restaurant]: RestaurantIcon,
    [PredefinedIconType.route]: RouteIcon,
    [PredefinedIconType.search]: SearchIcon,
    [PredefinedIconType.shop]: ShopIcon,
    [PredefinedIconType.sort]: SortIcon,
    [PredefinedIconType.user]: UserIcon,
    [PredefinedIconType.warning]: WarningIcon,
    [PredefinedIconType.ziel]: ZielIcon,
    [PredefinedIconType.edit]: EditIcon,
    [PredefinedIconType.dustbin]: DustBin,
    [PredefinedIconType.world]: WorldIcon,
    [PredefinedIconType.check]: CheckIcon,
    [PredefinedIconType.star]: StarIcon,
    [PredefinedIconType.truck]: TruckIcon,
    [PredefinedIconType.copy]: CopyIcon,
    [PredefinedIconType.plus]: PlusIcon,
    [PredefinedIconType.infoSmall]: InfoSmallIcon,
    [PredefinedIconType.bookmark]: BookmarkIcon,
    [PredefinedIconType.carCare1]: CarCareIcon1,
    [PredefinedIconType.carCare2]: CarCareIcon2,
    [PredefinedIconType.carCare3]: CarCareIcon3,
    [PredefinedIconType.carCare4]: CarCareIcon4,
};

export type PredefinedIconProps<t extends PredefinedIconType> = {
    type: t;
} & ComponentProps<(typeof icons)[PredefinedIconType.ziel]>;

const PredefinedIcon = <t extends PredefinedIconType>({
    type,
    ...props
}: PredefinedIconProps<t>) => {
    const Component = icons[type];

    if (!Component) throw new Error(`Unknown icon: ${type}`);

    return <Component {...(props as any)} />;
};

export default PredefinedIcon;
