import React, { useEffect } from "react";

import { AuthProvider } from "react-oidc-context";

import oidcConfig from "../../keycloak";
import { getUser, useAuthStore } from ".";

interface KeycloakProviderProps {
    children?: React.ReactNode;
}

const KeycloakProvider = ({ children }: KeycloakProviderProps) => {
    const { set, reset } = useAuthStore(({ set, reset }) => ({ set, reset }));

    // In case the user is already logged in
    useEffect(() => {
        const currentUser = getUser();

        if (currentUser) {
            console.log({ currentUser });
            set(currentUser);
        }
    }, [set]);

    return (
        <AuthProvider
            {...oidcConfig}
            onSigninCallback={(user) => (user ? set(user) : reset())}
            onRemoveUser={reset}
        >
            {children}
        </AuthProvider>
    );
};

export default KeycloakProvider;
