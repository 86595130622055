import { Int } from "./Brand";

type StorageImpl = Pick<Storage, "getItem" | "setItem" | "removeItem">;

export const mkStorage = (): StorageImpl => {
    const data = new Map();

    return {
        getItem: (k) => data.get(k) ?? null,
        setItem: (k, v) => data.set(k, String(v)),
        removeItem: (k) => data.delete(k),
    };
};

export const localStorage: StorageImpl =
    typeof window === "undefined" ? mkStorage() : window.localStorage;
export const sessionStorage: StorageImpl =
    typeof window === "undefined" ? mkStorage() : window.sessionStorage;

type PickType<t extends Record<string, any>, t2> = {
    [k in keyof t as t[k] extends t2 ? k : never]: Extract<t[k], t2>;
};
type SubtypingKeys<t extends Record<string, any>, t2> = Extract<
    keyof PickType<t, t2>,
    string
>;
type RestrictedType<
    t extends Record<string, any>,
    t2,
    k extends SubtypingKeys<t, t2>
> = Extract<PickType<t, t2>[k], t2>;

type TypeMap = {
    boolean: boolean;
    int: Int | number;
    record: Record<string, unknown>;
    array: any[];
    string: string;
    // Only allows has/remove
    unknown: unknown;
};

type UndefinedToNull<a> =
    | Exclude<a, undefined>
    | (a extends undefined ? null : never);

class StorageHandler<t extends Record<string, TypeMap[keyof TypeMap]>> {
    constructor(public storage: StorageImpl = localStorage) {
        this.storage = storage;
    }

    subtype<t2 extends t>(): StorageHandler<t2> {
        return new StorageHandler(this.storage);
    }

    getString<k extends SubtypingKeys<t, TypeMap["string"]>, fb>(
        key: k,
        fallbackValue: fb
    ): RestrictedType<t, TypeMap["string"], k> | UndefinedToNull<fb>;
    getString<k extends SubtypingKeys<t, TypeMap["string"]>>(
        key: k
    ): RestrictedType<t, TypeMap["string"], k> | null;
    getString<k extends SubtypingKeys<t, TypeMap["string"]>, fb>(
        key: k,
        fallbackValue: fb | null = null
    ): RestrictedType<t, TypeMap["string"], k> | fb | null {
        const data = this.getRaw(key as Extract<keyof t, string>);
        if (data === null || data === "null") {
            return fallbackValue;
        }
        return data as RestrictedType<t, TypeMap["string"], k>;
    }

    getBoolean<k extends SubtypingKeys<t, TypeMap["boolean"]>>(
        key: k
    ): RestrictedType<t, TypeMap["boolean"], k> {
        return (this.storage.getItem(key) === "true") as RestrictedType<
            t,
            TypeMap["boolean"],
            k
        >;
    }

    getInt<k extends SubtypingKeys<t, TypeMap["int"]>, fb>(
        key: k,
        fallbackValue: fb
    ): RestrictedType<t, TypeMap["int"], k> | UndefinedToNull<fb>;
    getInt<k extends SubtypingKeys<t, TypeMap["int"]>>(
        key: k
    ): RestrictedType<t, TypeMap["int"], k> | null;
    getInt<k extends SubtypingKeys<t, TypeMap["int"]>, fb>(
        key: k,
        fallbackValue: fb | null = null
    ): RestrictedType<t, TypeMap["int"], k> | fb | null {
        const data = this.getRaw(key as Extract<keyof t, string>);
        if (data === null || data === "null") {
            return fallbackValue;
        }
        return parseInt(data) as RestrictedType<t, TypeMap["int"], k>;
    }

    getRecord<k extends SubtypingKeys<t, TypeMap["record"]>, fb>(
        key: k,
        fallbackValue: fb
    ): RestrictedType<t, TypeMap["record"], k> | UndefinedToNull<fb>;
    getRecord<k extends SubtypingKeys<t, TypeMap["record"]>>(
        key: k
    ): RestrictedType<t, TypeMap["record"], k> | null;
    getRecord<k extends SubtypingKeys<t, TypeMap["record"]>, fb>(
        key: k,
        fallbackValue: fb | null = null
    ): RestrictedType<t, TypeMap["record"], k> | fb | null {
        const data = this.getRaw(key as Extract<keyof t, string>);
        if (data === null || data === "null") {
            return fallbackValue;
        }
        if (!data.includes("{")) {
            console.log("Invalid json in local storage", key, data);
        }
        return JSON.parse(data);
    }

    getArray<k extends SubtypingKeys<t, TypeMap["array"]>, fb>(
        key: k,
        fallbackValue: fb
    ): RestrictedType<t, TypeMap["array"], k> | UndefinedToNull<fb>;
    getArray<k extends SubtypingKeys<t, TypeMap["array"]>>(
        key: k
    ): RestrictedType<t, TypeMap["array"], k> | null;
    getArray<k extends SubtypingKeys<t, TypeMap["array"]>, fb>(
        key: k,
        fallbackValue: fb | null = null
    ): RestrictedType<t, TypeMap["array"], k> | fb | null {
        const data = this.getRaw(key as Extract<keyof t, string>);
        if (data === null || data === "null") {
            return fallbackValue;
        }
        if (!data.includes("[")) {
            console.log("Invalid json in local storage", key, data);
        }
        return JSON.parse(data);
    }

    /** @deprecated Use getRecord or getArray instead */
    getJson<k extends SubtypingKeys<t, TypeMap["record" | "array"]>, fb>(
        key: k,
        fallbackValue: fb
    ): RestrictedType<t, TypeMap["record" | "array"], k> | UndefinedToNull<fb>;
    getJson<k extends SubtypingKeys<t, TypeMap["record" | "array"]>>(
        key: k
    ): RestrictedType<t, TypeMap["record" | "array"], k> | null;
    getJson<k extends SubtypingKeys<t, TypeMap["record" | "array"]>, fb>(
        key: k,
        fallbackValue: fb | null = null
    ): RestrictedType<t, TypeMap["record" | "array"], k> | fb | null {
        const data = this.getRaw(key as Extract<keyof t, string>);
        if (data === null || data === "null") {
            return fallbackValue;
        }
        if (!data.includes("{") && !data.includes("[")) {
            console.log("Invalid json in local storage", key, data);
        }
        return JSON.parse(data);
    }

    private getRaw<k extends Extract<keyof t, string>>(key: k): string | null {
        return this.storage.getItem(key);
    }

    /**
     * Returns the value for the given key.
     * @deprecated Use getString; the null handling of get is inconsistent
     */
    get<k extends SubtypingKeys<t, string>, fb>(
        key: k,
        fallbackValue: fb | null = null
    ): RestrictedType<t, string, k> | UndefinedToNull<fb> | null {
        const data = this.getRaw(key as Extract<keyof t, string>);
        if (data === "null") {
            return null;
        }
        if (!data) {
            return fallbackValue;
        }
        return data as RestrictedType<t, string, k>;
    }

    private setRaw<k extends Extract<keyof t, string>>(
        key: k,
        value: string
    ): void {
        this.storage.setItem(key, value);
    }

    /**
     * Sets the given value to the given key.
     * @deprecated Use setString instead
     */
    set<k extends SubtypingKeys<t, string>>(
        key: k,
        value: RestrictedType<t, string, k>
    ): void {
        this.setRaw(key as Extract<keyof t, string>, value);
    }

    setBoolean<k extends SubtypingKeys<t, TypeMap["boolean"]>>(
        key: k,
        value: RestrictedType<t, TypeMap["boolean"], k>
    ): void {
        this.setRaw(
            key as Extract<keyof t, string>,
            (value as boolean) ? "true" : "false"
        );
    }

    setInt<k extends SubtypingKeys<t, TypeMap["int"]>>(
        key: k,
        value: RestrictedType<t, TypeMap["int"], k>
    ): void {
        this.setRaw(key as Extract<keyof t, string>, String(value));
    }

    setString<k extends SubtypingKeys<t, TypeMap["string"]>>(
        key: k,
        value: RestrictedType<t, TypeMap["string"], k>
    ): void {
        this.setRaw(key as Extract<keyof t, string>, value);
    }

    setRecord<k extends SubtypingKeys<t, TypeMap["record"]>>(
        key: k,
        value: RestrictedType<t, TypeMap["record"], k>
    ) {
        this.setRaw(key as Extract<keyof t, string>, JSON.stringify(value));
    }

    setArray<k extends SubtypingKeys<t, TypeMap["array"]>>(
        key: k,
        value: RestrictedType<t, TypeMap["array"], k>
    ) {
        this.setRaw(key as Extract<keyof t, string>, JSON.stringify(value));
    }

    /** @deprecated Use setRecord or setArray instead */
    setJson<k extends SubtypingKeys<t, TypeMap["record" | "array"]>>(
        key: k,
        value: RestrictedType<t, TypeMap["record" | "array"], k>
    ) {
        if (Array.isArray(value)) {
            this.setArray<Extract<k, SubtypingKeys<t, TypeMap["array"]>>>(
                key,
                value
            );
        }
        this.setRecord<Extract<k, SubtypingKeys<t, TypeMap["record"]>>>(
            key,
            value
        );
    }

    has(key: Extract<keyof t, string>): boolean {
        return this.storage.getItem(key) !== null;
    }

    remove(key: Extract<keyof t, string>): void {
        this.storage.removeItem(key);
    }
}

export default StorageHandler;

export type KnownLocalStorage = Record<string, never>;

export type KnownSessionStorage = {
    "app:parameter": string;
};

export const storageLocal = new StorageHandler<KnownLocalStorage>();
export const storageSession = new StorageHandler<KnownSessionStorage>(
    sessionStorage
);
