import React, { useState } from "react";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";

import { Permission, useHoyerAcl } from "../../../../../hooks/useHoyerAcl";
import {
    useIsAuthenticated,
    useKeycloakOpt,
} from "../../../../../providers/AuthProvider";
import { activeBorderBottom } from "../../../activeLink";
import Clickable from "../../../Clickable";
import { IconPredefined } from "../../../icons/Icon";
import { PredefinedIconType } from "../../../icons/Icon/Predefined";
import LinkComponent, { Link } from "../../../LinkComponent";
import SubNav from "./SubNav";

const LinkItemsContainer = styled.div`
    display: none;
    flex-grow: 1;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    ${({ theme }) => theme.breakpoints.from.normal.css`
        display: flex;
    `}
    ${({ theme }) => theme.breakpoints.from.normal.css`
        flex-grow: 0;
        padding: 0;
    `}
`;

const LinkItem = styled(Link)`
    flex-grow: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.red.toString()};
    font-weight: 500;

    ${({ theme }) => theme.breakpoints.from.normal.css`
        flex-grow: 0;
        padding: 0 0.8vw;
    `}

    ${({ theme }) => theme.breakpoints.from.large.css`
        flex-grow: 0;
        padding: 0 30px;
    `}

    ${({ theme }) => theme.selectors.hover.css`
        color: ${theme.colors.red.toString()};
    `}

    ${activeBorderBottom({ size: "4px", target: ".link-text-wrapper" })}
`;
const LinkItemClickable = LinkItem.withComponent(Clickable);

const LinkTextWrapper = styled.span`
    font-weight: 500;

    ${({ theme }) => theme.breakpoints.upTo.normal.css`
        font-size: 0.9063rem;
    `}
`;

const SubNavContainer = styled.div`
    flex-grow: 1;
    position: relative;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.from.normal.css`
        flex-grow: 0;
    `}

    > div {
        visibility: hidden;
    }

    &.active > div {
        visibility: visible;
    }

    ${activeBorderBottom({ size: "4px", target: ".link-text-wrapper" })}
`;

/**
 * Returns an array to create the account navigation.
 */
export const GetAccountSection = () => {
    const { can, canAny } = useHoyerAcl();
    const hoyerCardAccountLinks = [
        { href: "/hoyercard-portal", text: "Übersicht", permission: null },
        {
            href: "/hoyercard-portal/activation",
            text: <>Kundennummer&nbsp;aktivieren</>,
            permission: Permission.CUSTOMER_ACTIVATE_CUSTOMER,
        },
        {
            href: "/hoyercard-portal/admin",
            text: "Admin-Bereich",
            anyPermissions: [
                Permission.USER_CONNECT_TO_CUSTOMER,
                Permission.USER_GENERATE_ACTIVATION_CODE,
                Permission.USER_DISCONNECT_FROM_CUSTOMER,
            ],
        },
    ];

    const callForTenderAccountLinks = [
        { href: "/ausschreibungsportal", text: "Übersicht" },
        {
            href: "/ausschreibungsportal/profil",
            text: "Ausschreibungsprofil",
        },
        {
            href: "/ausschreibungsportal/admin",
            text: "Admin-Bereich",
            permission: Permission.AUSSCHREIBUNG_MANAGE,
        },
    ];

    return {
        name: "Mein Konto",
        subsections: [
            {
                name: "Hoyer Card Portal",
                items: hoyerCardAccountLinks.filter((link) => {
                    if (!link.permission && !link.anyPermissions) {
                        return true;
                    }
                    if (link.permission && can(link.permission)) {
                        return true;
                    }
                    return link.anyPermissions && canAny(link.anyPermissions);
                }),
            },
            {
                name: "Ausschreibungsportal",
                items: callForTenderAccountLinks.filter((link) => {
                    if (!link.permission) {
                        return true;
                    }
                    return link.permission && can(link.permission);
                }),
            },
        ],
    };
};

const LogoutSection = () => {
    const { authenticated, email } = useKeycloakOpt();
    const theme = useTheme();

    const containerStyles = css`
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid ${theme.colors.grey.toString()};
    `;

    if (!authenticated) {
        return null;
    }

    return (
        <div css={containerStyles}>
            <span>{email}</span>
            <LinkComponent button link={{ text: "Logout", href: "/logout" }} />
        </div>
    );
};

const LinkItems = ({ sections }) => {
    const [open, setOpen] = useState();
    const isAuthenticated = useIsAuthenticated();
    const theme = useTheme();
    const accountSection = GetAccountSection();

    return (
        <LinkItemsContainer>
            {sections.map((section, i) => {
                const link = (
                    <LinkTextWrapper className="link-text-wrapper">
                        {section.name.toLowerCase() === "translation" ? (
                            <IconPredefined
                                color={theme.colors.red.toString()}
                                type={PredefinedIconType.world}
                            />
                        ) : (
                            section.name
                        )}
                    </LinkTextWrapper>
                );

                return section.subsections && section.subsections.length > 0 ? (
                    <SubNavContainer
                        key={i}
                        className={classNames({ active: open === i })}
                        onMouseEnter={() => setOpen(i)}
                        onMouseLeave={() => setOpen()}
                        onFocus={() => setOpen(i)}
                        onBlur={() => open === i && setOpen()}
                    >
                        <LinkItemClickable
                            className={classNames({ active: open === i })}
                        >
                            {link}
                        </LinkItemClickable>

                        <SubNav subsections={section.subsections} />
                    </SubNavContainer>
                ) : (
                    <LinkItem key={i} to={section.href}>
                        {link}
                    </LinkItem>
                );
            })}
            {isAuthenticated && (
                <SubNavContainer
                    className={classNames({
                        active: open === "mein-konto",
                    })}
                    onMouseEnter={() => setOpen("mein-konto")}
                    onMouseLeave={() => setOpen()}
                    onFocus={() => setOpen("mein-konto")}
                    onBlur={() => open === "mein-konto" && setOpen()}
                >
                    <LinkItemClickable
                        className={classNames({
                            active: open === "mein-konto",
                        })}
                    >
                        <LinkTextWrapper className="link-text-wrapper">
                            {accountSection.name}
                        </LinkTextWrapper>
                    </LinkItemClickable>
                    <SubNav
                        subsections={accountSection.subsections}
                        isOpen={open === "mein-konto"}
                    >
                        <LogoutSection />
                    </SubNav>
                </SubNavContainer>
            )}
        </LinkItemsContainer>
    );
};

export default LinkItems;
