// extracted by mini-css-extract-plugin
export var center = "style-module--center--4ec80";
export var def = "style-module--def--5b04b";
export var five = "style-module--five--910d8";
export var four = "style-module--four--ddcf0";
export var headline = "style-module--headline--c06d8";
export var light = "style-module--light--0bf25";
export var lookLike = "style-module--lookLike--4813b";
export var one = "style-module--one--7295d";
export var three = "style-module--three--81edd";
export var two = "style-module--two--f7281";