import * as Ord_ from "fp-ts/lib/Ord";

import { Travis, travis_ } from "./NormalOrTravis";

export enum TravisServiceId {
    tankCleaning = "tankCleaning",
}

export type TravisService = {
    id: Travis<TravisServiceId>;
};

export const travisServiceTankCleaning: TravisService = {
    id: travis_(TravisServiceId.tankCleaning),
};

export const IdOrd = Ord_.fromCompare<TravisServiceId>(() => 0);
export const Ord = Ord_.fromCompare<TravisService>((a, b) =>
    IdOrd.compare(a.id.value, b.id.value)
);
