/**
 * Turns www/src/permissions.json into static enum-like objects
 */

import { useEffect } from "react";

import { pipe } from "fp-ts/lib/function";
import * as Rec from "fp-ts/lib/ReadonlyRecord";

import create from "zustand";
import * as zs from "zustand";

import { apiFetchProtected } from "../apiBindings";
import { CustomerId } from "../components/pages/Hcm/Dashboard/store";
import permissions from "../permissions.json";
import {
    getClaim,
    useAuthStore,
    useIsAuthenticated,
    UserKeycloakId,
} from "../providers/AuthProvider";
import * as AsyncData from "../types/AsyncData";
import { Branded, EmailAddress, Int } from "../utils/Brand";
import { storageLocal } from "../utils/StorageHandler";

/**
 * The raw permission data as they are returned from the introspection api;
 * `__typescriptHack` is added by www/scripts/refetch-data to allow access
 * to the constant string type of `value`.
 */
type PermissionDefinition<v extends string> = {
    category: string;
    description: string;
    display_name: string;
    /**
     * This is used to gain access to the constant type
     * of `value`; effectively just `{ [value]: {} }`.
     * This is added by www/scripts/refetch-data as it isn't part of the
     * api response.
     */
    __typescriptHack: Record<v, any>;
    value: string;
};

type AnyPermissionDefinition = PermissionDefinition<string>;

const permissionGroupValues = <
    a extends Record<string, AnyPermissionDefinition>
>(
    group: a
): {
    [k in keyof a]: a[k] extends PermissionDefinition<infer v> ? v : never;
} =>
    pipe(
        group,
        Rec.map(({ value }) => value)
    ) as any;

const mapAllPermissions = <
    a extends Record<string, Record<string, AnyPermissionDefinition>>
>(
    permissions: a
): {
    [k in keyof a]: {
        [k2 in keyof a[k]]: a[k][k2] extends PermissionDefinition<infer v>
            ? v
            : never;
    };
} => pipe(permissions, Rec.map(permissionGroupValues)) as any;

type RecordValues<a extends Record<string, any>> = a[keyof a];

// New permission enums must be named here for them to be usable in the client
export const {
    card: CardPermission,
    customerAccess: CustomerAccessPermission,
    customerAdmin: CustomerAdminPermission,
    faq: FaqPermission,
    invoice: InvoicePermission,
    role: RolePermission,
    service: ServicePermission,
    tender: TenderPermission,
    transaction: TransactionPermission,
    user: UserPermission,
    vehicle: VehiclePermission,
} = mapAllPermissions(permissions);

export type CardPermission = RecordValues<typeof CardPermission>;
export type CustomerAccessPermission = RecordValues<
    typeof CustomerAccessPermission
>;
export type CustomerAdminPermission = RecordValues<
    typeof CustomerAdminPermission
>;
export type FaqPermission = RecordValues<typeof FaqPermission>;
export type InvoicePermission = RecordValues<typeof InvoicePermission>;
export type RolePermission = RecordValues<typeof RolePermission>;
export type ServicePermission = RecordValues<typeof ServicePermission>;
export type TenderPermission = RecordValues<typeof TenderPermission>;
export type TransactionPermission = RecordValues<typeof TransactionPermission>;
export type UserPermission = RecordValues<typeof UserPermission>;
export type VehiclePermission = RecordValues<typeof VehiclePermission>;

type UnionToIntersection<U> = (U extends any ? (x: U) => void : never) extends (
    x: infer I
) => void
    ? I
    : never;

/**
 * This turns the namespaced permission records into a record of all permissions,
 * with their keys prefixed accordingly.
 */
const prefixedPermissions = <a extends Record<string, Record<string, string>>>(
    permissions: a
): UnionToIntersection<
    {
        [k in keyof a]: k extends string
            ? {
                  [k2 in keyof a[k]]: k2 extends string
                      ? {
                            [k3 in `${k}_${k2}`]: a[k][k2];
                        }
                      : never;
              }[keyof a[k]]
            : never;
    }[keyof a]
> =>
    Object.fromEntries(
        Object.entries(permissions).flatMap(([k, group]) =>
            Object.entries(group).map(([k2, v]) => [`${k}_${k2}`, v])
        )
    ) as any;

/** @deprecated Use CardPermission etc instead */
export const Permission = prefixedPermissions({
    CARD: CardPermission,
    CUSTOMER_ACCESS: CustomerAccessPermission,
    CUSTOMER_ADMIN: CustomerAdminPermission,
    FAQ: FaqPermission,
    INVOICE: InvoicePermission,
    ROLE: RolePermission,
    SERVICE: ServicePermission,
    TENDER: TenderPermission,
    TRANSACTION: TransactionPermission,
    USER: UserPermission,
    VEHICLE: VehiclePermission,
    CUSTOMER: {
        ACTIVATE_CUSTOMER: UserPermission.ACTIVATE_CUSTOMER,
    },
});
export type Permission = RecordValues<typeof Permission>;

type CustomerPermissionKey = `CUSTOMER_${number}`;

type HoyerAcl = {
    USER: Permission[];
    CUSTOMER_NUMBERS: {
        [key: CustomerPermissionKey]: Permission[];
    };
};

export type UserIdBrand = { readonly UserId: unique symbol };
export type UserId = Branded<Int, UserIdBrand>;

type AuthUser = {
    id: UserId;
    keycloak_id: UserKeycloakId;
    email: EmailAddress;
    name: string;
    email_verified_at: Date | null;
    created_at: Date | null;
    updated_at: Date | null;
};
type RawAuthUser = {
    [k in keyof AuthUser]: Date extends AuthUser[k]
        ? Exclude<AuthUser[k], Date> | string
        : AuthUser[k];
} & {
    acl: HoyerAcl;
};

type AuthUserResponse = {
    data: RawAuthUser;
};

type HoyerAclStore = {
    subject: UserKeycloakId | null;
    user: AsyncData.AsyncData<Error, AuthUser>;
    acl: AsyncData.AsyncData<Error, HoyerAcl>;
    reset: () => void;
    abort: () => void;
    fetch: () => Promise<void>;
    canGlobally: (permission: Permission) => boolean;
    canForCustomer: (permission: Permission, customerId: CustomerId) => boolean;
    canForCurrentCustomer: (permission: Permission) => boolean;
    can: (permission: Permission) => boolean;
};

const currentCustomerId = () => {
    const customerId = storageLocal.getInt("selectedCustomerNumber") ?? null;

    if (!customerId) return null;
    return customerId as CustomerId;
};

const initialState = {
    subject: null,
    user: AsyncData.notLoaded<Error, AuthUser>(),
    acl: AsyncData.notLoaded<Error, HoyerAcl>(),
};

let abortController: AbortController | null = null;
export const useHoyerAclStore = create<HoyerAclStore>((set, get) => ({
    ...initialState,
    reset: () => {
        get().abort();
        set(initialState);
    },
    abort: () => {
        abortController?.abort();
        abortController = null;
    },
    fetch: async () => {
        const { abort, subject, reset } = get();

        const newSubject = useAuthStore.getState().userKeycloakId;

        if (!newSubject) {
            reset();
            return;
        }

        if (newSubject !== subject) {
            abort();
            set({
                subject: newSubject,
                user: AsyncData.loading(),
                acl: AsyncData.loading(),
            });
        }

        abortController = new AbortController();

        try {
            const {
                data: {
                    acl,
                    email_verified_at,
                    created_at,
                    updated_at,
                    ...user
                },
            } = await apiFetchProtected<AuthUserResponse>("/hoyer-acl/me", {
                signal: abortController.signal,
            });

            set({
                user: AsyncData.loaded({
                    ...user,
                    email_verified_at:
                        email_verified_at == null
                            ? null
                            : new Date(email_verified_at),
                    created_at:
                        created_at == null ? null : new Date(created_at),
                    updated_at:
                        updated_at == null ? null : new Date(updated_at),
                }),
                acl: AsyncData.loaded(acl),
            });
        } catch (e) {
            console.error("Error fetching user info:", e);
            set({
                user: AsyncData.error(e as Error),
                acl: AsyncData.error(e as Error),
            });
        }
    },
    canGlobally: (permission: Permission): boolean => {
        const { acl } = get();
        if (!AsyncData.isLoaded(acl)) {
            return false;
        }

        // Check if the user has direct permission otherwise.
        return acl.data.USER.includes(permission);
    },
    canForCustomer: (
        permission: Permission,
        customerId: CustomerId
    ): boolean => {
        const { acl } = get();

        if (!AsyncData.isLoaded(acl)) {
            return false;
        }

        return (
            acl.data.CUSTOMER_NUMBERS[`CUSTOMER_${customerId}`]?.includes(
                permission
            ) ?? false
        );
    },
    canForCurrentCustomer: (permission: Permission): boolean => {
        // If a customer is set, check first if the user has the permission for the customer;
        const customerId = currentCustomerId();

        if (!customerId) return false;
        return get().canForCustomer(permission, customerId as CustomerId);
    },
    can: (permission: Permission): boolean => {
        const { canGlobally, canForCurrentCustomer } = get();
        return canGlobally(permission) || canForCurrentCustomer(permission);
    },
}));

const subscribe1 = <a extends zs.State>(
    listener: (state: a, previousState?: a) => void,
    store: zs.StoreApi<a>
) => {
    const unsub = store.subscribe(listener);
    listener(store.getState());
    return unsub;
};
subscribe1(({ userKeycloakId }) => {
    const state = useHoyerAclStore.getState();

    if (!userKeycloakId) {
        state.reset();
    } else if (userKeycloakId !== state.subject) {
        void state.fetch();
    }
}, useAuthStore);

export const useHoyerAcl = () => {
    const store = useHoyerAclStore();

    return {
        /**
         * Checks if the user has a permission either direct permission or indirect through
         * his currently selected customer.
         * @param permission
         * @param onFail
         * @deprecated Use `useHoyerAclStore().can(permission)` instead
         */
        can: (permission: Permission, onFail?: () => void): boolean => {
            if (store.can(permission)) return true;

            // Execute the callback if a callback function is provided e.g., redirect or something.
            onFail && onFail();
            return false;
        },

        /**
         * Checks if a user has at least one of the given permissions.
         * @param permissions
         * @param onFail
         * @deprecated Use `permissions.some((perm) => useHoyerAclStore().can(perm))` instead
         */
        canAny: (permissions: Permission[], onFail?: () => void): boolean => {
            if (permissions.some((perm) => store.can(perm))) {
                return true;
            }

            onFail && onFail();
            return false;
        },

        /**
         * @deprecated Use `useHoyerAclStore().user` instead
         */
        getUser: (): RawAuthUser | undefined => {
            return AsyncData.toUndefined(
                AsyncData.Monad.chain(
                    store.user,
                    ({ email_verified_at, created_at, updated_at, ...user }) =>
                        AsyncData.map(
                            (acl): RawAuthUser => ({
                                email_verified_at: String(email_verified_at),
                                created_at: String(created_at),
                                updated_at: String(updated_at),
                                ...user,
                                acl,
                            }),
                            store.acl
                        )
                )
            );
        },
    };
};
