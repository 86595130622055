import { useEffect, useState } from "react";

import { AuthContextProps, hasAuthParams, useAuth } from "react-oidc-context";

import { type JwtPayload, jwtDecode } from "jwt-decode";
import { IdTokenClaims, User } from "oidc-client-ts";
import create from "zustand";

import { authority, clientId, redirectUri } from "../../keycloak";
import { Branded } from "../../utils/Brand";
import { storageSession } from "../../utils/StorageHandler";

const fallbackPreviousAuthPath = "/hoyercard-portal";
const previousPathStorageKey = "auth:previous-path";

export type UserKeycloakIdBrand = { readonly UserKeycloakId: unique symbol };
export type UserKeycloakId = Branded<string, UserKeycloakIdBrand>;

export type AuthState =
    | {
          user: null;
          userKeycloakId: null;
      }
    | {
          user: User;
          userKeycloakId: UserKeycloakId;
      };

export type AuthActions = {
    set: (user: User) => void;
    reset: () => void;
};

export type AuthStore = AuthState & AuthActions;

const initialState = {
    user: null,
    userKeycloakId: null,
};

export const useAuthStore = create<AuthStore>((set, get) => ({
    ...initialState,
    set: (user) =>
        set({ user, userKeycloakId: user.profile.sub as UserKeycloakId }),
    reset: () => set(initialState),
}));

export const useKeycloakOpt = (): {
    authenticated: boolean;
    email?: string;
} => {
    const auth = useAuth();
    const isAuthenticated = useIsAuthenticated();
    if (isAuthenticated) {
        const decodedToken = decodeAccessToken(auth);
        if (decodedToken !== null) {
            return {
                authenticated: true,
                email: decodedToken.email,
            };
        }
    }

    return {
        authenticated: false,
        email: undefined,
    };
};

export const decodeAccessToken = (
    auth: AuthContextProps
): KeycloakJwtToken | null => {
    if (auth.user) {
        return jwtDecode(auth.user.access_token) as KeycloakJwtToken;
    }
    return null;
};

export const useLogout = () => {
    const auth = useAuth();

    return () => {
        void auth.removeUser();
        cleanOidcEntriesFromStorage();
        void auth.signoutRedirect({
            post_logout_redirect_uri: window.location.origin,
        });
    };
};

export const useAutomaticallySignIn = () => {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    // automatically sign-in
    useEffect(() => {
        if (
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading &&
            !hasTriedSignin
        ) {
            setPreviousAuthPath();
            void auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);
};

export const useIsAuthenticated = (): boolean | undefined => {
    const auth = useAuth();

    return auth && auth.isAuthenticated && !auth.error;
};

export interface KeycloakJwtToken extends JwtPayload {
    email: string;
    realm_access?: {
        roles?: Array<string>;
    };
}

export const cleanOidcEntriesFromStorage = () => {
    const allKeys = Object.keys(localStorage);
    allKeys.forEach((key) => {
        if (key.startsWith("oidc.")) {
            localStorage.removeItem(key);
        }
    });
};

export const getUser = (): User | undefined => {
    const oidcStorage = storageSession.getString(
        `oidc.user:${authority}:${clientId}`
    );

    return oidcStorage == null
        ? undefined
        : User.fromStorageString(oidcStorage) || undefined;
};

export const getAccessToken = (): string | undefined => getUser()?.access_token;

export const getClaim = <claim extends keyof IdTokenClaims>(claim: claim) =>
    getUser()?.profile[claim];

export const getPreviousAuthPath = (): string => {
    const prevPath = storageSession.get(
        previousPathStorageKey,
        fallbackPreviousAuthPath
    );

    if (!prevPath || prevPath.includes("login-redirect")) {
        return fallbackPreviousAuthPath;
    }
    return prevPath;
};

export const hasPreviousAuthPath = (): boolean => {
    return storageSession.get(previousPathStorageKey) !== null;
};

export const setPreviousAuthPath = () => {
    let prevPath =
        window.location.pathname +
        window.location.search +
        window.location.hash;
    if (prevPath.startsWith(redirectUri)) {
        return;
    }
    if (prevPath.includes("login-redirect")) {
        prevPath = fallbackPreviousAuthPath;
    }
    storageSession.set("auth:previous-path", prevPath);
};
