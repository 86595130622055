/* global google */

import { useEffect } from "react";

import * as Eq from "fp-ts/lib/Eq";
import { pipe } from "fp-ts/lib/function";
import * as num from "fp-ts/lib/number";
import * as Opt from "fp-ts/lib/Option";
import * as Ord from "fp-ts/lib/Ord";
import * as Arr from "fp-ts/lib/ReadonlyArray";
import * as Mp from "fp-ts/lib/ReadonlyMap";
import * as St from "fp-ts/lib/ReadonlySet";
import * as Semi from "fp-ts/lib/Semigroup";

import LatLon from "geodesy/latlon-ellipsoidal-vincenty";
import create from "zustand";
import { devtools } from "zustand/middleware";

import * as api from "../../../apiBindings/gasStations";
import { env } from "../../../config";
import { pushToDataLayer } from "../../../utils/analytics";
import {
    mkRoute,
    replaceRoute,
    RouteFinder,
    RouteFinderEq,
} from "../../shared/RouteLink/Route";
import { europeIsh } from "./hardCodedMapSearchBounds";
import * as AsyncData from "../../../types/AsyncData";
import * as FuelType from "./types/FuelType";
import * as GasStationType from "./types/GasStationType";
import { GasStation, Station, TravisStation } from "./types/index";
import * as NormalOrTravis from "./types/NormalOrTravis";
import * as PaymentMethod from "./types/PaymentMethod";
import * as Property from "./types/Property";
import * as Service from "./types/Service";
import * as Station_ from "./types/Station";
import * as StationType from "./types/StationType";
import * as TravisService from "./types/TravisService";
import * as TravisStationType from "./types/TravisStationType";

export const setToMap = <a>(st: ReadonlySet<a>) =>
    new Map([...st].map((v) => [v, v]));

export const mapKeys =
    <k, k2, a>(
        k: Ord.Ord<k>,
        k2: Ord.Ord<k2>,
        m: Semi.Semigroup<a>,
        f: (k: k) => k2
    ) =>
    (v: ReadonlyMap<k, a>): ReadonlyMap<k2, a> =>
        pipe(
            v,
            Mp.toReadonlyArray(k),
            Arr.map(([k, v]) => [f(k), v] as const),
            Mp.fromFoldable(k2, m, Arr.Foldable)
        );

export const mapById = <id, a = { id: id }>(
    ord: Ord.Ord<a>,
    idOrd: Ord.Ord<id>,
    vals: ReadonlySet<a>,
    f: (a: a) => id
) => pipe(vals, setToMap, mapKeys(ord, idOrd, Semi.first<a>(), f));
export const defaultMapById = <a extends { id: any }>(
    ord: Ord.Ord<a>,
    idOrd: Ord.Ord<a["id"]>,
    vals: ReadonlySet<a>
) => mapById(ord, idOrd, vals, (a) => a.id);

export enum HoyerStationType {
    all = "all",
    gasStation = "gasStation",
    vendingMachine = "vendingMachine",
}
const HoyerStationTypeOrder = [
    HoyerStationType.all,
    HoyerStationType.gasStation,
    HoyerStationType.vendingMachine,
];
export const HoyerStationTypeOrd = Ord.fromCompare<HoyerStationType>((a, b) =>
    num.Ord.compare(
        HoyerStationTypeOrder.indexOf(a),
        HoyerStationTypeOrder.indexOf(b)
    )
);

export enum AcceptancePartnerStationType {
    all = "all",
    gasStation = "gasStation",
    vendingMachine = "vendingMachine",
}
const AcceptancePartnerStationTypeOrder = [
    AcceptancePartnerStationType.all,
    AcceptancePartnerStationType.gasStation,
    AcceptancePartnerStationType.vendingMachine,
];
export const AcceptancePartnerStationTypeOrd =
    Ord.fromCompare<AcceptancePartnerStationType>((a, b) =>
        num.Ord.compare(
            AcceptancePartnerStationTypeOrder.indexOf(a),
            AcceptancePartnerStationTypeOrder.indexOf(b)
        )
    );

export enum PaymentType {
    hoyerApp = "hoyerApp", // {name = Hoyer APP , id = 2}
    hoyerCard = "hoyerCard", // {name = Hoyer Card, id = 3}
}

const PaymentOrder = [PaymentType.hoyerApp, PaymentType.hoyerCard];
export const PaymentOrd = Ord.fromCompare<PaymentType>((a, b) =>
    num.Ord.compare(PaymentOrder.indexOf(a), PaymentOrder.indexOf(b))
);

type Distance = { unit: "km"; value: number };

type Filter = {
    fuelTypes: ReadonlySet<FuelType.FuelTypeId>;
    properties: ReadonlySet<Property.PropertyId>;
    hoyerStationTypes: ReadonlySet<HoyerStationType>;
    acceptancePartnerStationTypes: ReadonlySet<AcceptancePartnerStationType>;
    premium: boolean;
    paymentTypes: ReadonlySet<PaymentType>;
    circumscribedArea: {
        radius: Distance | null;
    };
};

export type FinderGasStation = Omit<
    GasStation,
    "availableFuelTypes" | "availableServices" | "type"
> & {
    availableFuelTypes: ReadonlySet<FuelType.FuelTypeId>;
    availableProperties: ReadonlySet<Property.PropertyId>;
    type: GasStationType.GasStationType;
};

export type FinderTravisStation = Omit<
    TravisStation,
    "availableFuelTypes" | "availableServices" | "type"
> & {
    availableFuelTypes: ReadonlySet<FuelType.FuelTypeId>;
    availableProperties: ReadonlySet<Property.PropertyId>;
    type: TravisStationType.TravisStationType;
};

export type FinderStation = FinderGasStation | FinderTravisStation;

export const FinderStationOrd = Ord.fromCompare<FinderStation>((a, b) =>
    Station_.IdOrd.compare(a.id, b.id)
);

export const finderStationIsFinderGasStation = (
    fs: FinderStation
): fs is FinderGasStation => NormalOrTravis.isNormal(fs.id);
export const finderStationIsFinderTravisStation = (
    fs: FinderStation
): fs is FinderTravisStation => NormalOrTravis.isTravis(fs.id);

export const isTravisFilterActiveAndStationIsFinderStation = (
    properties: ReadonlySet<Property.PropertyId>,
    station: FinderStation
) =>
    properties.has(Property.travisPropertyId) &&
    finderStationIsFinderTravisStation(station);

type FilterFn<extra extends ReadonlyArray<any> = []> = (
    filter: Filter,
    ...extra: extra
) => (stations: ReadonlyArray<FinderStation>) => ReadonlyArray<FinderStation>;

const hasAllFuelTypes = (
    test: ReadonlySet<FuelType.FuelTypeId>,
    available: ReadonlySet<FuelType.FuelTypeId>
) => St.isSubset(FuelType.IdOrd)(test, available);

const filterFuelTypes: FilterFn = (filter) => (stations) =>
    filter.fuelTypes.size === 0
        ? stations
        : stations.filter(
              (station) =>
                  isTravisFilterActiveAndStationIsFinderStation(
                      filter.properties,
                      station
                  ) ||
                  hasAllFuelTypes(filter.fuelTypes, station.availableFuelTypes)
          );

// If the travis prop /is not/ selected, then travis stations are excluded.
// If the travis prop /is/ selected, then /both/ travis and non-travis stations are included.
// For all other props it's just checked whether the station's offer is a superset of the filter.
const hasAllProperties = (
    test: ReadonlySet<Property.PropertyId>,
    available: ReadonlySet<Property.PropertyId>
) =>
    St.isSubset(Property.IdOrd)(
        St.remove(Property.IdOrd)(Property.travisPropertyId)(test),
        available
    ) &&
    (test.has(Property.travisPropertyId) ||
        !available.has(Property.travisPropertyId));

const filterProperties: FilterFn = (filter) => (stations) =>
    stations.filter(
        (station) =>
            isTravisFilterActiveAndStationIsFinderStation(
                filter.properties,
                station
            ) ||
            hasAllProperties(filter.properties, station.availableProperties)
    );

const isHoyerStationType = (
    test: HoyerStationType,
    available: StationType.StationType
) =>
    StationType.stationTypeIsGasStation(available) &&
    [
        GasStationType.GasStationOwner.hoyer,
        GasStationType.GasStationOwner.partner,
    ].includes(available.owner)
        ? test === HoyerStationType.gasStation
            ? available.gasStation
            : test === HoyerStationType.vendingMachine
            ? available.vendingMachineOnly
            : (false as never)
        : true;

const filterHoyerStationType: FilterFn = (filter) => (stations) =>
    filter.hoyerStationTypes.size === 0
        ? stations.filter(
              (station) =>
                  !StationType.stationTypeIsGasStation(station.type) ||
                  ![
                      GasStationType.GasStationOwner.hoyer,
                      GasStationType.GasStationOwner.partner,
                  ].includes(station.type.owner)
          )
        : filter.hoyerStationTypes.size === 1 &&
          filter.hoyerStationTypes.has(HoyerStationType.all)
        ? stations
        : stations.filter((station) =>
              St.some((test: HoyerStationType) =>
                  isHoyerStationType(test, station.type)
              )(filter.hoyerStationTypes)
          );

const isAcceptancePartnerStationType = (
    test: AcceptancePartnerStationType,
    available: FinderStation
) =>
    finderStationIsFinderGasStation(available) &&
    available.type.owner === GasStationType.GasStationOwner.acceptancePartner
        ? test === AcceptancePartnerStationType.gasStation
            ? available.type.gasStation
            : test === AcceptancePartnerStationType.vendingMachine
            ? available.type.vendingMachineOnly
            : (false as never)
        : true;

const filterAcceptancePartnerStationType: FilterFn = (filter) => (stations) =>
    filter.acceptancePartnerStationTypes.size === 0
        ? stations.filter(
              (station) =>
                  !StationType.stationTypeIsGasStation(station.type) ||
                  station.type.owner !==
                      GasStationType.GasStationOwner.acceptancePartner
          )
        : filter.acceptancePartnerStationTypes.size === 1 &&
          filter.acceptancePartnerStationTypes.has(
              AcceptancePartnerStationType.all
          )
        ? stations
        : stations.filter((station) =>
              St.some((test: AcceptancePartnerStationType) =>
                  isAcceptancePartnerStationType(test, station)
              )(filter.acceptancePartnerStationTypes)
          );

const isPremium = (test: boolean, available: FinderStation) =>
    test
        ? finderStationIsFinderGasStation(available) && available.premium
        : (false as never);

const filterPremium: FilterFn = (filter) => (stations) =>
    filter.premium
        ? stations.filter(
              (station) =>
                  isTravisFilterActiveAndStationIsFinderStation(
                      filter.properties,
                      station
                  ) || isPremium(filter.premium, station)
          )
        : stations;

const canPayBy = (test: PaymentType, available: FinderStation) =>
    finderStationIsFinderGasStation(available) &&
    available.supportedPaymentMethods.includes(
        test === PaymentType.hoyerApp
            ? (2 as PaymentMethod.PaymentMethodId)
            : test === PaymentType.hoyerCard
            ? (3 as PaymentMethod.PaymentMethodId)
            : (0 as never)
    );

const filterPaymentType: FilterFn = (filter) => (stations) =>
    filter.paymentTypes.size === 0
        ? stations
        : stations.filter(
              (station) =>
                  isTravisFilterActiveAndStationIsFinderStation(
                      filter.properties,
                      station
                  ) ||
                  St.some((test: PaymentType) => canPayBy(test, station))(
                      filter.paymentTypes
                  )
          );

const asMetre = (distance: Distance): number => distance.value * 1000;

const latLngToLiteral = (
    latLng: google.maps.LatLng
): google.maps.LatLngLiteral => ({
    lat: latLng.lat(),
    lng: latLng.lng(),
});

const directionsCircumscribedArea: Distance = {
    unit: "km",
    value: 10,
};

const filterDirections: FilterFn<[directions?: google.maps.DirectionsResult]> =
    (_, directions) => (stations) =>
        directions
            ? stations.filter((station) =>
                  directions.routes.some(({ overview_path }) =>
                      overview_path.some(
                          (geo) =>
                              new LatLon(geo.lat(), geo.lng()).distanceTo(
                                  new LatLon(station.geo.lat, station.geo.lng)
                              ) <= asMetre(directionsCircumscribedArea)
                      )
                  )
              )
            : stations;

const filterCircumscribedArea: FilterFn<
    [location: AsyncData.AsyncData<unknown, Location>]
> = (filter, location) => (stations) => {
    const { radius } = filter.circumscribedArea;

    return AsyncData.isLoaded(location) && radius
        ? stations.filter(
              (station) =>
                  new LatLon(
                      location.data.geo.lat,
                      location.data.geo.lng
                  ).distanceTo(new LatLon(station.geo.lat, station.geo.lng)) <=
                  asMetre(radius)
          )
        : stations;
};

const filterGasStations: FilterFn<
    [
        location: AsyncData.AsyncData<unknown, Location>,
        directions?: google.maps.DirectionsResult
    ]
> = (filter, location, directions) => (gasStations) =>
    pipe(
        gasStations,
        filterHoyerStationType(filter),
        filterAcceptancePartnerStationType(filter),
        filterPremium(filter),
        filterPaymentType(filter),
        filterProperties(filter),
        filterFuelTypes(filter),
        filterCircumscribedArea(filter, location),
        filterDirections(filter, directions)
    );

export const propertyServices = (
    props: Property.Property[]
): Service.Service[] =>
    props.filter(Property.propertyIsServiceProperty).map((p) => p.value);

const hydrateStation =
    (
        fuelTypesById: ReadonlyMap<FuelType.FuelTypeId, FuelType.FuelType>,
        propertiesById: ReadonlyMap<Property.PropertyId, Property.Property>,
        stationTypesById: ReadonlyMap<
            StationType.StationTypeId,
            StationType.StationType
        >
    ) =>
    ({
        availableFuelTypes,
        availableServices,
        type,
        id,
        ...props
    }: Station): FinderStation => ({
        availableFuelTypes: pipe(
            availableFuelTypes,
            Arr.filter((id) => Mp.member(FuelType.IdOrd)(id, fuelTypesById)),
            St.fromReadonlyArray(FuelType.IdOrd)
        ),
        availableProperties: pipe(
            availableServices,
            Arr.map(Property.servicePropertyId),
            Arr.filter((id: Property.PropertyId) =>
                Mp.member(Property.IdOrd)(id, propertiesById)
            ),
            Arr.concat(
                NormalOrTravis.isTravis(id) ? [Property.travisPropertyId] : []
            ),
            St.fromReadonlyArray(Property.IdOrd)
        ),
        type: pipe(
            stationTypesById,
            Mp.lookup(StationType.IdOrd)(type),
            Opt.fold(
                () => undefined as any as StationType.StationType,
                (a) => a
            )
        ) as any,
        id: id as any,
        ...props,
    });

const allAvailableProperties = (
    services: ReadonlySet<Service.Service>
): ReadonlySet<Property.Property> =>
    St.union(Property.Ord)(
        St.map(Property.Ord)(Property.serviceProperty)(services),
        St.fromReadonlyArray(Property.Ord)([
            Property.travisProperty,
            Property.serviceProperty(TravisService.travisServiceTankCleaning),
        ])
    );

const availableProperties = (services: ReadonlySet<Service.Service>) =>
    St.union(Property.Ord)(
        pipe(
            services,
            St.filter(
                (s: Service.Service) =>
                    NormalOrTravis.isNormal(s.id) &&
                    [
                        9, 18, 20, 2, 24, 28, 8, 11, 4, 7, 29, 10, 31, 32, 30,
                        5,
                    ].includes(s.id.value)
            ),
            St.map(Property.Ord)(Property.serviceProperty)
        ),
        St.fromReadonlyArray(Property.Ord)([Property.travisProperty])
    );

const emptyFilter: Filter = {
    fuelTypes: new Set(),
    properties: new Set(),
    hoyerStationTypes: new Set([HoyerStationType.all]),
    acceptancePartnerStationTypes: new Set([AcceptancePartnerStationType.all]),
    premium: true,
    paymentTypes: new Set(),
    circumscribedArea: {
        radius: null,
    },
};

const devtoolsMiddleware = ["production", "test"].includes(env)
    ? (((a) => a) as typeof devtools)
    : devtools;

const initialMap: Store["map"] = {
    center: {
        lat: 51.0,
        lng: 9.0,
    },
    zoom: 7,
    directions: undefined,
};

const geocode = async (req: google.maps.GeocoderRequest) =>
    (await new google.maps.Geocoder().geocode(req)).results;
const route = async (req: google.maps.DirectionsRequest) =>
    new google.maps.DirectionsService().route(req);

type Bounds = {
    north: number;
    east: number;
    south: number;
    west: number;
};
type Location = {
    address: string;
    geo: google.maps.LatLngLiteral;
};
type UnloadedLocation =
    | Location
    | {
          address: string;
          geo: null;
      };
type PartialLocation =
    | UnloadedLocation
    | {
          address: null;
          geo: null;
      };

const normaliseLocation =
    (bounds?: Bounds) =>
    async (location: UnloadedLocation): Promise<Location> => {
        if (location.geo) return location as Location;
        if (!bounds) {
            bounds = europeIsh;
        }
        const [point] = await geocode({
            address: location.address,
            bounds,
        });

        return {
            address: point.formatted_address,
            geo: latLngToLiteral(point.geometry.location),
        };
    };

const getGeolocation = async () => {
    if (navigator.geolocation) {
        const { coords } = await new Promise<GeolocationPosition>(
            (resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            }
        );

        const data = {
            lat: coords.latitude,
            lng: coords.longitude,
        };

        const [point] = await geocode({
            location: data,
        });

        return {
            geolocation: data,
            newLocation: {
                address: point.formatted_address,
                geo: latLngToLiteral(point.geometry.location),
            },
            map: {
                center: data,
                zoom: 11,
            },
        };
    } else {
        throw new Error("Not supported");
    }
};

type Map = {
    center: google.maps.LatLngLiteral;
    zoom: number;
    directions?: google.maps.DirectionsResult;
};
const MapEq = Eq.struct<Map>({
    center: Eq.struct({
        lat: num.Eq,
        lng: num.Eq,
    }),
    zoom: num.Eq,
    directions: Eq.fromEquals((a, b) => a === b),
});

type Store = {
    availableOptions: AsyncData.AsyncData<
        unknown,
        {
            fuelTypes: ReadonlySet<FuelType.FuelType>;
            allProperties: ReadonlySet<Property.Property>;
            properties: ReadonlySet<Property.Property>;
            paymentMethods: ReadonlySet<PaymentMethod.PaymentMethod>;
            gasStationTypes: ReadonlySet<StationType.StationType>;
        }
    >;
    gasStations: AsyncData.AsyncData<unknown, ReadonlyArray<FinderStation>>;
    filteredGasStations: AsyncData.AsyncData<
        unknown,
        ReadonlyArray<FinderStation>
    >;
    gasStationBounds: AsyncData.AsyncData<unknown, Bounds | undefined>;
    selectedGasStation: FinderStation | null;
    location: AsyncData.AsyncData<unknown, Location>;
    newLocation: PartialLocation;
    routeEnd: AsyncData.AsyncData<unknown, Location> | null;
    newRouteEnd: PartialLocation;
    isSelectingRoute: boolean;
    filter: Filter;
    loading: ReadonlyArray<"initial">;
    loadingPrices: boolean;
    geolocation: AsyncData.AsyncData<
        unknown,
        {
            lat: number;
            lng: number;
        }
    >;
    map: {
        center: google.maps.LatLngLiteral;
        zoom: number;
        directions?: google.maps.DirectionsResult;
    };
    loadGasStations: () => Promise<void>;
    selectGasStation: (selectedGasStation: FinderStation) => void;
    unselectGasStation: () => void;
    setLocation: (address: PartialLocation["address"]) => void;
    setLocationFromPlace: (
        place: google.maps.places.PlaceResult | google.maps.GeocoderResult
    ) => void;
    setRouteEnd: (address: PartialLocation["address"]) => void;
    setRouteEndFromPlace: (
        place: google.maps.places.PlaceResult | google.maps.GeocoderResult
    ) => void;
    saveLocation: () => Promise<void>;
    resetMap: () => void;
    setMapCenter: (center: google.maps.LatLngLiteral, zoom?: number) => void;
    setInitialMapCenterFromGeolocation: () => Promise<void>;
    setMapCenterFromGeolocation: () => Promise<void>;
    resetGeolocation: () => void;
    startSelectingRoute: () => void;
    stopSelectingRoute: () => void;
    setFilterFuelTypes: (fuelTypes: ReadonlySet<FuelType.FuelTypeId>) => void;
    setFilterProperties: (properties: ReadonlySet<Property.PropertyId>) => void;
    setFilterHoyerStationTypes: (
        stationTypes: ReadonlySet<HoyerStationType>
    ) => void;
    setFilterAcceptancePartnerStationTypes: (
        stationTypes: ReadonlySet<AcceptancePartnerStationType>
    ) => void;
    setFilterPremium: (premium: boolean) => void;
    setFilterPayments: (payments: ReadonlySet<PaymentType>) => void;
    setFilterCircumscribedAreaRadius: (radius: Distance | null) => void;
    setFilterFromRoute: (route: RouteFinder) => void;
    getFilterAsRoute: () => RouteFinder;
    resetFilter: () => void;
};

const useGasStationsStore = create<Store>(
    devtoolsMiddleware(
        (set, get) => ({
            availableOptions: AsyncData.notLoaded(),
            gasStations: AsyncData.notLoaded(),
            filteredGasStations: AsyncData.notLoaded(),
            gasStationBounds: AsyncData.notLoaded(),
            selectedGasStation: null,
            location: AsyncData.notLoaded(),
            newLocation: {
                address: null,
                geo: null,
            },
            routeEnd: null,
            newRouteEnd: {
                address: null,
                geo: null,
            },
            isSelectingRoute: false,
            filter: emptyFilter,
            loading: ["initial"],
            loadingPrices: false,
            geolocation: AsyncData.notLoaded(),
            map: initialMap,
            loadGasStations: async () => {
                if (!AsyncData.isNotLoaded(get().gasStations)) {
                    return;
                }

                set(
                    {
                        gasStations: AsyncData.loading(),
                        filteredGasStations: AsyncData.loading(),
                        gasStationBounds: AsyncData.loading(),
                        availableOptions: AsyncData.loading(),
                    } as Pick<
                        Store,
                        | "gasStations"
                        | "filteredGasStations"
                        | "gasStationBounds"
                        | "availableOptions"
                    >,
                    undefined,
                    "loadGasStations/start"
                );

                // There's no risk of the Promise being rejected as we're catching with the pure AsyncData.error
                const [
                    gasStations,
                    fuelTypes,
                    services,
                    paymentMethods,
                    gasStationTypes,
                ] = await Promise.all([
                    AsyncData.fromApiResponse<ReadonlyArray<Station>>(
                        api.gasStations({})
                    ),
                    AsyncData.fromApiResponse<ReadonlyArray<FuelType.FuelType>>(
                        api.fuelTypes({})
                    ),
                    AsyncData.fromApiResponse<ReadonlyArray<Service.Service>>(
                        api.services({})
                    ),
                    AsyncData.fromApiResponse<
                        ReadonlyArray<PaymentMethod.PaymentMethod>
                    >(api.paymentMethods({})),
                    AsyncData.fromApiResponse<
                        ReadonlyArray<StationType.StationType>
                    >(api.stationTypes({})),
                ]);

                const availableOptions = AsyncData.sequenceS({
                    fuelTypes: AsyncData.map(
                        (fuelTypes) =>
                            St.fromReadonlyArray(FuelType.Ord)(
                                fuelTypes
                                    .map((ft, i) => ({ ...ft, i }))
                                    .filter(({ id }) => id !== 19)
                            ),
                        fuelTypes
                    ),
                    allProperties: AsyncData.map(
                        (services) =>
                            allAvailableProperties(
                                St.fromReadonlyArray(Service.Ord)(services)
                            ),
                        services
                    ),
                    properties: AsyncData.map(
                        (services) =>
                            availableProperties(
                                St.fromReadonlyArray(Service.Ord)(services)
                            ),
                        services
                    ),
                    paymentMethods: AsyncData.map(
                        St.fromReadonlyArray(PaymentMethod.Ord),
                        paymentMethods
                    ),
                    gasStationTypes: AsyncData.map(
                        St.fromReadonlyArray(StationType.Ord),
                        gasStationTypes
                    ),
                });

                const hydratedGasStations = AsyncData.map(
                    ({
                        availableOptions: {
                            fuelTypes,
                            allProperties,
                            gasStationTypes,
                        },
                        gasStations,
                    }) =>
                        new Set(
                            gasStations.map(
                                hydrateStation(
                                    defaultMapById(
                                        FuelType.Ord,
                                        FuelType.IdOrd,
                                        fuelTypes
                                    ),
                                    pipe(allProperties, (a) =>
                                        mapById(
                                            Property.Ord,
                                            Property.IdOrd,
                                            a,
                                            Property.propertyId
                                        )
                                    ),
                                    defaultMapById(
                                        StationType.Ord,
                                        StationType.IdOrd,
                                        gasStationTypes
                                    )
                                )
                            )
                        ),
                    AsyncData.sequenceS({ gasStations, availableOptions })
                );

                if (!AsyncData.isLoaded(gasStations)) {
                    return undefined;
                }

                const gasStationBounds = AsyncData.map((gasStations) => {
                    const { lat, lng } = gasStations.reduce(
                        (agg, { geo: { lat, lng } }) => ({
                            lat: [
                                Math.min(agg.lat[0], lat),
                                Math.max(agg.lat[1], lat),
                            ],
                            lng: [
                                Math.min(agg.lng[0], lng),
                                Math.max(agg.lng[1], lng),
                            ],
                        }),
                        {
                            lat: [Infinity, -Infinity],
                            lng: [Infinity, -Infinity],
                        }
                    );

                    if (
                        [lat[0], lng[0]].includes(Infinity) ||
                        [lat[1], lng[1]].includes(-Infinity)
                    ) {
                        return undefined;
                    }

                    return {
                        north: lat[1],
                        east: lng[1],
                        south: lat[0],
                        west: lng[0],
                    };
                }, gasStations);

                set(
                    ({ filter, map, location }) => ({
                        gasStations: AsyncData.map(
                            (stations) => Array.from(stations),
                            hydratedGasStations
                        ),
                        filteredGasStations: AsyncData.map(
                            (stations) =>
                                filterGasStations(
                                    filter,
                                    location,
                                    map.directions
                                )(Array.from(stations)),
                            hydratedGasStations
                        ),
                        gasStationBounds,
                        availableOptions,
                    }),
                    undefined,
                    "loadGasStations/success"
                );
            },
            selectGasStation: (selectedGasStation) =>
                set({ selectedGasStation }, undefined, "selectGasStation"),
            unselectGasStation: () =>
                set(
                    { selectedGasStation: null },
                    undefined,
                    "unselectGasStation"
                ),
            setLocation: (address) =>
                set(
                    { newLocation: { address, geo: null } },
                    undefined,
                    "setLocation"
                ),
            setLocationFromPlace: (place) =>
                set(
                    {
                        newLocation: place.formatted_address
                            ? {
                                  address: place.formatted_address,
                                  geo: place.geometry?.location
                                      ? latLngToLiteral(place.geometry.location)
                                      : null,
                              }
                            : {
                                  address: null,
                                  geo: null,
                              },
                    },
                    undefined,
                    "setLocationFromPlace"
                ),
            setRouteEnd: (address) => {
                const currentState = get();
                set(
                    {
                        newRouteEnd: { address, geo: null },
                        filter: {
                            ...currentState.filter,
                            circumscribedArea: {
                                radius: null,
                            },
                        },
                    },
                    undefined,
                    "setRouteEnd"
                );
            },
            setRouteEndFromPlace: (place) =>
                set(
                    {
                        newRouteEnd: place.formatted_address
                            ? {
                                  address: place.formatted_address,
                                  geo: place.geometry?.location
                                      ? latLngToLiteral(place.geometry.location)
                                      : null,
                              }
                            : {
                                  address: null,
                                  geo: null,
                              },
                    },
                    undefined,
                    "setRouteEndFromPlace"
                ),
            saveLocation: async () => {
                const {
                    newLocation,
                    newRouteEnd,
                    isSelectingRoute,
                    setInitialMapCenterFromGeolocation,
                    setLocation,
                    setRouteEnd,
                } = get();

                if (
                    !newLocation.address &&
                    (!isSelectingRoute || !newRouteEnd.address)
                ) {
                    setInitialMapCenterFromGeolocation();
                    setLocation(null);
                    setRouteEnd(null);
                    return;
                }

                set(
                    {
                        location: AsyncData.loading(),
                        routeEnd: isSelectingRoute ? AsyncData.loading() : null,
                    },
                    undefined,
                    "saveLocation/start"
                );

                try {
                    const [location, routeEnd] = await Promise.all(
                        [
                            newLocation as UnloadedLocation,
                            isSelectingRoute &&
                                (newRouteEnd as UnloadedLocation),
                        ]
                            .filter(
                                (
                                    a: false | UnloadedLocation
                                ): a is UnloadedLocation => a !== false
                            )
                            .map(
                                normaliseLocation(
                                    AsyncData.toUndefined(
                                        get().gasStationBounds
                                    )
                                )
                            )
                    );

                    const directions =
                        routeEnd &&
                        (await route({
                            origin: location.geo,
                            destination: routeEnd.geo,
                            travelMode: google.maps.TravelMode.DRIVING,
                        }));

                    set(
                        ({ filter, gasStations }) => ({
                            location: AsyncData.loaded(location),
                            routeEnd: isSelectingRoute
                                ? AsyncData.loaded(routeEnd)
                                : null,
                            map: {
                                center: location.geo,
                                zoom: 12,
                                directions,
                            },
                            filteredGasStations: AsyncData.map(
                                filterGasStations(
                                    filter,
                                    AsyncData.loaded(location),
                                    directions
                                ),
                                gasStations
                            ),
                        }),
                        undefined,
                        "saveLocation/success"
                    );
                } catch (e) {
                    console.log(e);
                    set(
                        {
                            location: AsyncData.error(e),
                            routeEnd: isSelectingRoute
                                ? AsyncData.error(e)
                                : null,
                        },
                        undefined,
                        "saveLocation/error"
                    );
                }
            },
            resetMap: () =>
                set(
                    {
                        map: initialMap,
                        location: AsyncData.notLoaded(),
                        newLocation: {
                            address: null,
                            geo: null,
                        },
                        routeEnd: null,
                        newRouteEnd: {
                            address: null,
                            geo: null,
                        },
                        isSelectingRoute: false,
                    },
                    undefined,
                    "resetMap"
                ),
            setMapCenter: (center, zoom) => {
                const map = { center, zoom: zoom ? Number(zoom) : 13 };
                if (MapEq.equals(map, get().map)) return;

                set({ map }, undefined, "setMapCenter");
            },
            setInitialMapCenterFromGeolocation: async () => {
                // We're only notifying the redux-devtools that the action happened.
                set({}, undefined, "setInitialMapCenterFromGeolocation/start");

                try {
                    const { geolocation, newLocation, map } =
                        await getGeolocation();

                    set(
                        {
                            geolocation: AsyncData.loaded(geolocation),
                            newLocation,
                            map,
                        },
                        undefined,
                        "setInitialMapCenterFromGeolocation/success"
                    );
                    get().saveLocation();
                } catch (e) {
                    // We're just setting map to initialMap in case of error;
                    // since the user didn't interact with the app to get their location
                    // they won't care about the error.
                    set(
                        {
                            map: initialMap,
                        },
                        undefined,
                        "setInitialMapCenterFromGeolocation/error"
                    );
                }
            },
            setMapCenterFromGeolocation: async () => {
                set(
                    { geolocation: AsyncData.loading() },
                    undefined,
                    "setMapCenterFromGeolocation/start"
                );

                try {
                    const { geolocation, newLocation, map } =
                        await getGeolocation();

                    set(
                        {
                            geolocation: AsyncData.loaded(geolocation),
                            newLocation,
                            map,
                        },
                        undefined,
                        "setMapCenterFromGeolocation/success"
                    );
                    get().saveLocation();
                } catch (error) {
                    set(
                        { geolocation: AsyncData.error(error) },
                        undefined,
                        "setMapCenterFromGeolocation/error"
                    );
                }
            },
            resetGeolocation: () =>
                set(
                    { geolocation: AsyncData.notLoaded() },
                    undefined,
                    "resetGeolocation"
                ),
            startSelectingRoute: () =>
                set(
                    { isSelectingRoute: true },
                    undefined,
                    "startSelectingRoute"
                ),
            stopSelectingRoute: () =>
                set(
                    { isSelectingRoute: false },
                    undefined,
                    "stopSelectingRoute"
                ),
            setFilterFuelTypes: (fuelTypes) =>
                set(
                    ({ filter, gasStations, map, location }) => ({
                        filter: { ...filter, fuelTypes },
                        filteredGasStations: AsyncData.map(
                            filterGasStations(
                                {
                                    ...filter,
                                    fuelTypes,
                                },
                                location,
                                map.directions
                            ),
                            gasStations
                        ),
                    }),
                    undefined,
                    "setFilterFuelTypes"
                ),
            setFilterProperties: (properties) =>
                set(
                    ({ filter, gasStations, map, location }) => ({
                        filter: { ...filter, properties },
                        filteredGasStations: AsyncData.map(
                            filterGasStations(
                                { ...filter, properties },
                                location,
                                map.directions
                            ),
                            gasStations
                        ),
                    }),
                    undefined,
                    "setFilterProperties"
                ),
            setFilterHoyerStationTypes: (newVal) =>
                set(
                    ({ filter, gasStations, map, location }) => {
                        const resetStationTypes =
                            newVal.size === 0 &&
                            filter.acceptancePartnerStationTypes.size === 0;

                        const hoyerStationTypes = resetStationTypes
                            ? emptyFilter.hoyerStationTypes
                            : newVal.has(HoyerStationType.all)
                            ? new Set([HoyerStationType.all])
                            : newVal;

                        const acceptancePartnerStationTypes = resetStationTypes
                            ? emptyFilter.acceptancePartnerStationTypes
                            : newVal.has(HoyerStationType.all) ||
                              newVal.size === 0
                            ? filter.acceptancePartnerStationTypes
                            : St.remove(AcceptancePartnerStationTypeOrd)(
                                  AcceptancePartnerStationType.all
                              )(filter.acceptancePartnerStationTypes);

                        const premium = resetStationTypes
                            ? emptyFilter.premium
                            : filter.premium;

                        return {
                            filter: {
                                ...filter,
                                hoyerStationTypes,
                                acceptancePartnerStationTypes,
                                premium,
                            },
                            filteredGasStations: AsyncData.map(
                                filterGasStations(
                                    {
                                        ...filter,
                                        hoyerStationTypes,
                                        acceptancePartnerStationTypes,
                                        premium,
                                    },
                                    location,
                                    map.directions
                                ),
                                gasStations
                            ),
                        };
                    },
                    undefined,
                    "setFilterHoyerStationType"
                ),
            setFilterAcceptancePartnerStationTypes: (newVal) =>
                set(
                    ({ filter, gasStations, map, location }) => {
                        const resetStationTypes =
                            newVal.size === 0 &&
                            filter.hoyerStationTypes.size === 0;

                        const acceptancePartnerStationTypes = resetStationTypes
                            ? emptyFilter.acceptancePartnerStationTypes
                            : newVal.has(AcceptancePartnerStationType.all)
                            ? new Set([AcceptancePartnerStationType.all])
                            : newVal;

                        const hoyerStationTypes = resetStationTypes
                            ? emptyFilter.hoyerStationTypes
                            : newVal.has(AcceptancePartnerStationType.all) ||
                              newVal.size === 0
                            ? filter.hoyerStationTypes
                            : St.remove(HoyerStationTypeOrd)(
                                  HoyerStationType.all
                              )(filter.hoyerStationTypes);

                        const premium = resetStationTypes
                            ? emptyFilter.premium
                            : newVal.has(AcceptancePartnerStationType.all)
                            ? false
                            : filter.premium;

                        return {
                            filter: {
                                ...filter,
                                hoyerStationTypes,
                                acceptancePartnerStationTypes,
                                premium,
                            },
                            filteredGasStations: AsyncData.map(
                                filterGasStations(
                                    {
                                        ...filter,
                                        hoyerStationTypes,
                                        acceptancePartnerStationTypes,
                                        premium,
                                    },
                                    location,
                                    map.directions
                                ),
                                gasStations
                            ),
                        };
                    },
                    undefined,
                    "setFilterAcceptancePartnerStationType"
                ),
            setFilterPremium: (premium) =>
                set(
                    ({ filter, gasStations, map, location }) => {
                        const acceptancePartnerStationTypes =
                            premium &&
                            filter.acceptancePartnerStationTypes.size === 0
                                ? new Set([AcceptancePartnerStationType.all])
                                : filter.acceptancePartnerStationTypes;

                        return {
                            filter: {
                                ...filter,
                                premium,
                                acceptancePartnerStationTypes,
                            },
                            filteredGasStations: AsyncData.map(
                                filterGasStations(
                                    {
                                        ...filter,
                                        premium,
                                        acceptancePartnerStationTypes,
                                    },
                                    location,
                                    map.directions
                                ),
                                gasStations
                            ),
                        };
                    },
                    undefined,
                    "setFilterPremium"
                ),
            setFilterPayments: (paymentTypes) =>
                set(
                    ({ filter, gasStations, map, location }) => {
                        const resetTypes =
                            paymentTypes.size !== 0 &&
                            filter.hoyerStationTypes.size === 0 &&
                            filter.acceptancePartnerStationTypes.size === 0;

                        return {
                            filter: {
                                ...filter,
                                paymentTypes,
                                hoyerStationTypes: resetTypes
                                    ? emptyFilter.hoyerStationTypes
                                    : filter.hoyerStationTypes,
                                acceptancePartnerStationTypes: resetTypes
                                    ? emptyFilter.acceptancePartnerStationTypes
                                    : filter.acceptancePartnerStationTypes,
                            },
                            filteredGasStations: AsyncData.map(
                                filterGasStations(
                                    {
                                        ...filter,
                                        paymentTypes,
                                        hoyerStationTypes: resetTypes
                                            ? emptyFilter.hoyerStationTypes
                                            : filter.hoyerStationTypes,
                                        acceptancePartnerStationTypes:
                                            resetTypes
                                                ? emptyFilter.acceptancePartnerStationTypes
                                                : filter.acceptancePartnerStationTypes,
                                    },
                                    location,
                                    map.directions
                                ),
                                gasStations
                            ),
                        };
                    },
                    undefined,
                    "setFilterPayments"
                ),
            setFilterCircumscribedAreaRadius: (radius) =>
                set(
                    ({ filter, gasStations, map, location }) => {
                        pushToDataLayer(
                            "gasStations:finder:filter:circumscribedArea",
                            {
                                radius: radius
                                    ? `${radius.value} ${radius.unit}`
                                    : null,
                            }
                        );

                        return {
                            filter: {
                                ...filter,
                                circumscribedArea: {
                                    ...filter.circumscribedArea,
                                    radius,
                                },
                            },
                            filteredGasStations: AsyncData.map(
                                filterGasStations(
                                    {
                                        ...filter,
                                        circumscribedArea: {
                                            ...filter.circumscribedArea,
                                            radius,
                                        },
                                    },
                                    location,
                                    map.directions
                                ),
                                gasStations
                            ),
                        };
                    },
                    undefined,
                    "setFilterCircumscribedAreaRadius"
                ),
            setFilterFromRoute: ({
                location: location_,
                properties = emptyFilter.properties,
                hoyerStationTypes = emptyFilter.hoyerStationTypes,
                acceptancePartnerStationTypes = emptyFilter.acceptancePartnerStationTypes,
                fuelTypes = emptyFilter.fuelTypes,
                premium = emptyFilter.premium,
                paymentTypes = emptyFilter.paymentTypes,
                map: { center = initialMap.center, zoom = initialMap.zoom },
            }) => {
                const location =
                    location_ == null
                        ? undefined
                        : location_ === "27374"
                        ? "DE-27374"
                        : location_;

                const filter: Filter = {
                    properties,
                    hoyerStationTypes,
                    acceptancePartnerStationTypes,
                    fuelTypes,
                    premium,
                    paymentTypes,
                    circumscribedArea: emptyFilter.circumscribedArea,
                };

                set(
                    ({ gasStations }) => ({
                        filter,
                        filteredGasStations: AsyncData.map(
                            filterGasStations(filter, AsyncData.notLoaded()),
                            gasStations
                        ),
                    }),
                    undefined,
                    "resetFilter"
                );

                if (location) {
                    get().setLocation(location);
                    get().saveLocation();
                }

                if (center && !get().map.directions) {
                    get().setMapCenter(center, zoom);
                } else if (center) {
                    get().saveLocation();
                } else if (!get().map.directions) {
                    get().setInitialMapCenterFromGeolocation();
                }
            },
            getFilterAsRoute: () => {
                const {
                    location,
                    filter: {
                        properties,
                        hoyerStationTypes,
                        acceptancePartnerStationTypes,
                        fuelTypes,
                        premium,
                        paymentTypes,
                    },
                    map: { center, zoom },
                } = get();

                return {
                    location: AsyncData.toUndefined(location)?.address,
                    properties,
                    hoyerStationTypes,
                    acceptancePartnerStationTypes,
                    fuelTypes,
                    premium,
                    paymentTypes,
                    map: { center, zoom },
                };
            },
            resetFilter: () =>
                set(
                    ({ gasStations, map }) => ({
                        filter: emptyFilter,
                        filteredGasStations: AsyncData.map(
                            filterGasStations(
                                emptyFilter,
                                AsyncData.notLoaded()
                            ),
                            gasStations
                        ),
                        routeEnd: null,
                        newRouteEnd: {
                            address: null,
                            geo: null,
                        },
                        map: {
                            ...map,
                            directions: undefined,
                        },
                        isSelectingRoute: false,
                    }),
                    undefined,
                    "resetFilter"
                ),
        }),
        "GasStations"
    )
);

export default useGasStationsStore;

export const useFinderUpdateLocation = () => {
    const route = useGasStationsStore(
        (store) => store.getFilterAsRoute(),
        (a, b) => RouteFinderEq.equals(a, b)
    );

    useEffect(() => {
        replaceRoute(mkRoute.RouteFinder(route));
    }, [route]);
};
